import React from "react"
import Layout from "../components/layout"
import HeadLabel from "../components/head-label"
import Seo from "../components/seo"

export default function Privacy() {
  return (
    <Layout>
      <Seo
        title="Privacy | 株式会社GoodTe | グッテ"
        description="株式会社GoodTeのプライバシポリシーについて掲載しています。"
        ogpSiteName="Privacy | 株式会社GoodTeコーポレートサイト"
      />
      <div className="m-5">
        <HeadLabel label={"Cookieの取り扱いについて"} />

        <div className="px-3 typography mb-8">
          <div className="mb-3">免責事項</div>
          <ul className="list-disc">
            <li className="mb-2">
              当サイトに記載されている記事内容は投稿日に基づいているため、情報が古くなっている可能性もございますが、掲載されている内容につきまして、一切の保証を致しかねます。
            </li>
            <li className="mb-2">
              また、当サイトにて紹介させていただいている情報などが発端で発生しましたトラブルや損害につきまして、一切責任を負わないものとします。
            </li>
            <li className="mb-2">
              十分ご理解いただいた上、ご自身の判断でご利用くださいますと幸いです。よろしくお願い申し上げます。
            </li>
          </ul>
        </div>

        <div className="px-3 typography mb-8">
          <div className="text-xl mb-3">Cookie</div>
          <ul className="list-disc">
            <li className="mb-2">
              Cookieとは、お客様がインターネット閲覧した際に、アクセス情報がコンピュータ内に記録される機能のことをいいます。
            </li>
            <li className="mb-2">
              ただし、氏名や住所、電話番号やメールアドレスといった重要な個人情報はCookieには一切含まれません。
            </li>
            <li className="mb-2">
              お客様がどのようなサービスや情報に興味や関心をお持ちなのかを分析し、ウェブ上での効果的な広告の配信の為に、アクセス情報を使用させていただく場合がございます。
            </li>
            <li className="mb-2">
              万が一、Cookieを使用した情報収集を拒否したい場合は、一部のサービスが正しく機能しなくなる場合もございますが、ご自身にて、Cookieの受け入れ拒否の設定をすることも可能です。
            </li>
          </ul>
        </div>

        <div className="px-3 typography mb-8">
          <div className="text-xl mb-3">他サイトからの埋め込みコンテンツ</div>
          <ul className="list-disc">
            <li className="mb-2">
              このサイトの投稿には埋め込みコンテンツ
              (動画、画像、投稿など)が含まれます。他サイトからの埋め込みコンテンツは、訪問者がそのサイトを訪れた場合とまったく同じように振る舞います。
            </li>
            <li className="mb-2">
              これらのサイトは、あなたのデータの収集、Cookieの使用、サードパーティによる追加トラッキングの埋め込み、埋め込みコンテンツとのやりとりの監視を行うことがあります。アカウントを使ってそのサイトにログイン中の場合、埋め込みコンテンツとのやりとりのトラッキングも含まれます。
            </li>
          </ul>
        </div>

        <div className="px-3 typography mb-8">
          <div className="text-xl mb-3">あなたのデータの送信先</div>
          <ul className="list-disc">
            <li className="mb-2">
              訪問者によるコメントは、自動スパム検出サービスを通じて確認を行う場合があります。
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
